import { useState } from "react";
export const CardDetails = () => {
    const [cardDetails, setCardDetails] = useState([
        {
            icon: "fa-solid fa-circle-check",
            text: "This card is sold exclusively here and can be bought anywhere else.",
          },
          {
            icon: "fa-solid fa-circle-check",
            text: `These cards are solely a souvenir for clients who passionately support
                   Donald Trump’s leadership, and they will not be used for anything other
                   than a simple item for showing their support.`,
          },
          {
            icon: "fa-solid fa-circle-check",
            text: `About 74 million Americans are Trump supporters so if you’re looking for
                   an item that will be loved by everyone, then the answer is here.`,
          },
          {
            icon: "fa-solid fa-circle-check",
            text: `​​Indulge yourself in complete security as this platinum card holds a 14-Day
                  Money-Back Guarantee. So if you’re unsatisfied, you’ll immediately get a
                  full refund.`,
          },
          {
            icon: "fa-solid fa-circle-check",
            text: `​Now is the time to get your hands on a very limited edition, silver-plated
                  America Comeback Card.`,
          },
    
    ]);
  
    return (
      <div className="card-details px-2">
        {cardDetails.map((detail, index) => (
          <div key={index} className="details-div py-2 "  style={{fontSize:"18px"}}>
            <i className={detail.icon} style={{ paddingTop: '5px', color: '#ff4500', fontSize: '20px' }}></i>
            <p style={{ color: 'white', marginLeft: '5px' }}>{detail.text}</p>
          </div>
        ))}
      </div>
    );
  };
  