import React, { useState } from 'react';
import { Container } from 'react-bootstrap';

const questionsData = [
  {
    question: "Is it free shipping?",
    answer: "Yes, we provide free shipping & handling on all the orders! Once you place the order, our team in Colorado will handle your products within 5-7 business days. (Please note: we currently handle too many shipments so your order might take up to 3 weeks)",
  },
  {
    question: "Can I get support when the product is defective?",
    answer: "Please feel free to email us, and our team will resolve any issue.",
  },
  {
    question: "What type of shipping method do you use?",
    answer: "We provide a standard shipping method.",
  },
  {
    question: "When will I receive notification of my shipment?",
    answer: "As soon as we receive the order, you will receive a notification of the shipment. Shipping usually takes 5-7 business days. (Please note: we currently handle too many shipments so your order might take up to 3 weeks)",
  },
];

const FaqComponent = () => {
  const [activeIndexes, setActiveIndexes] = useState([]);

  const handleQuestionClick = (index) => {
    if (activeIndexes.includes(index)) {
      setActiveIndexes(activeIndexes.filter((item) => item !== index));
    } else {
      setActiveIndexes([...activeIndexes, index]);
    }
  };

  const isExpanded = (index) => activeIndexes.includes(index);

  return (
    <Container className="faq text-left py-5   w-70" style={{ cursor:"pointer"}}>
      
      {questionsData.map((item, index) => (
        <div key={index} className="faq-item pb-6  ">
            <div className='border-bottom pb-6'></div>
          <div
            className="faq-question"
            onClick={() => handleQuestionClick(index)}
          >
             <div className='d-flex justify-content-between'>
            <h3 style={{fontSize:"22px"}}>
            {item.question}{' '}
            </h3>
            {isExpanded(index) ? (
              <h3 role="img" aria-label="Close">
                x
              </h3>
            ) : (
              <h3 role="img" aria-label="Open">
                +
              </h3>
            )}
           </div>
          </div>
          {isExpanded(index) && <p className="faq-answer pl-5" style={{fontSize:"18px"}}>{item.answer}</p>}
         
        </div>
      ))}
      <div className='border-bottom'></div>
    </Container>
  );
};

export default FaqComponent;

