// CardDetailsComponent.js
import React from 'react';
import { priceData } from './cardData';
import { Col } from 'react-bootstrap';

export const OffersContainer = () => {
  return (
    <Col id="divContainer">
    {priceData.map((item, index) => (
      <div key={index} className="price-container" style={{ marginTop: index >= 4 ? '70px' : '0px', textAlign: "center", position: "relative" }}>
        {item.offer && (
          <h4 className="package-offer px-5 py-2"  style={{ fontSize: "16px",textTransform:"uppercase", fontWeight: "bold", backgroundColor:"red"}}>
            {item.offer}
          </h4>
        )}
        <a target="_blank" href={item.link} style={{ textDecoration: "none" }}>
          <p style={{ fontSize: "24px", fontWeight: "bolder" }} className="price-tag pb-0 mb-0">
            {item.text}
          </p>
          <p className="description">{item.description}</p>
        </a>
      </div>
    ))}
  </Col>
  );
};
