import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button, Container, Row, Col } from "react-bootstrap";
import Image from "react-bootstrap";
import card from "./images/card_back_trump-removebg-preview.png";
import cardBack from "./images/new_trump-removebg-preview.png";
import donaldWallper from "./images/donald wallper.png";
import { CardDetails } from "./components/cardDetails";
import { CardContainer } from "./components/cardConainer";
import { OffersContainer } from "./components/offersContainer";
import visaLogo from "./images/VISA-Logo-1992.png";
import americanExpressLogo from "./images/american-express-card4564-removebg-preview.png";
import masterCardLogo from "./images/mastercard1.png";
import applePayLogo from "./images/apple-pay-og-twitter.jpg";
import googlePayLogo from "./images/og_image.jpg";
import FaqComponent from "./components/frequentlyAskedQuestions";
function App() {
  return (
    <div
      className="App"
      style={{ fontFamily: "Poppins,Helvetica, sans-serif" }}
    >
      {/* --------------------------------HEADER-------------------------- */}
      <header className="pb-5 pt-0 ">
        <Container className="pt-5" id="#landing">
          <Row className=" pt-3">
            <Col lg={6} md={12} sm={12} className="text-left">
              <div className="pb-4">
                <img
                  src="https://d33wubrfki0l68.cloudfront.net/2a951110d6ff2e88a45c0893324fd461ac675719/21a35/assets/logo-1.png"
                  alt="logo"
                  width="150"
                />
              </div>

              <h1
                className="text-uppercase text-white"
                style={{
                  fontSize: "47px",
                  letterSpacing: "3px",
                  fontWeight: "bolder",
                }}
              >
                Your <span>America comeback card</span> is waiting to be in your
                hands, now with <span> 70% discount</span>
              </h1>
              <div className="mt-5 pt-4 image-container">
                <img src={card} alt="card" />
              </div>
              <div className="mt-1 image-container-two px-5">
                <img src={cardBack} alt="card" />
              </div>
            </Col>
            <Col
              lg={6}
              md={12}
              sm={12}
              className="text-left"
              id="comeback-card"
            >
              <h2
                className=" text-uppercase text-white"
                style={{ fontWeight: "bold", fontSize: "45px" }}
              >
                America comeback <br />
                2024 card
              </h2>
              <h2
                className="h1 text-capitalize py-2"
                style={{
                  fontSize: "52px",
                  color: "rgb(228, 203, 94)",
                  fontWeight: "bolder",
                  letterSpacing: "3px",
                }}
              >
                starting at 199.99$
              </h2>
              <blockquote
                className="h4 text-white  mt-3"
                style={{ fontSize: "20px" }}
              >
                <span>★★★★★</span> (4.95/5 by organization gurus)
              </blockquote>

              <ul className="pt-3 pb-3 " style={{ fontWeight: "bolder" }}>
                <li className="text-capitalize">
                  <i className="fa-solid fa-lock"></i>100% satisfaction
                  guaranteed
                </li>
                <li className="text-capitalize">
                  <i className="fa-solid fa-circle-check"></i> the leading card
                  for certified by supporters
                </li>
                <li className="text-capitalize">
                  <i className="fa-solid fa-truck"></i>Ships anywhere with
                  14-day MONEY-BACK GUARANTEE
                </li>
              </ul>
              <h3
                className="text-uppercase text-center py-1 my-4"
                style={{
                  backgroundColor: "rgb(228, 203, 94)",
                  fontWeight: "bold",
                }}
              >
                grab the deal
              </h3>

              <div id="divContainer" className="mt-2">
                <OffersContainer />
              </div>
              <div
                className="cards-logos-container px-1 text-center"
                style={{ width: "60%" }}
              >
                <img src={visaLogo} alt="Visa Logo " width="60px" />
                <img
                  src={americanExpressLogo}
                  alt="American Express Logo"
                  width="60px"
                />
                <img src={masterCardLogo} alt="Master Card Logo" width="60px" />
                <img src={applePayLogo} alt="Apple Pay" width="55px" />
                <img src={googlePayLogo} alt="Google Pay" width="55px" />
              </div>
            </Col>
          </Row>
        </Container>
      </header>
      {/* --------------------------------MAIN------------------------------ */}
      <main className="bg-dark">
        <Container>
          <Row className="pt-5 justify-content-center">
            <Col lg={5} md={12} sm={12}>
              <img
                src={donaldWallper}
                alt="Donald Trump"
                className="donald-wallper"
              />
              <h2
                className="text-center pt-5 text-white text-capitalize pb-2"
                style={{ fontSize: "52px", fontWeight: "bolder" }}
              >
                Get your very own <span>america comeback card</span>
              </h2>
            </Col>
            <Col lg={6} md={12} sm={12} className="card-details">
              <CardDetails />
            </Col>
          </Row>
          <div className="d-flex justify-content-center">
            <span
              className="py-4"
              style={{ borderBottom: "1px solid white", width: "45%" }}
            ></span>
          </div>
          <div className="text-center text-white py-5">
            <p
              className=" text-uppercase"
              style={{ fontSize: "50px", fontWeight: "bolder" }}
            >
              act now
            </p>
            <h4 className="pt-3">
              Supplies are limited and will surely sell out quickly
            </h4>
          </div>
          <div id="cardContainer" className="py-3">
            <CardContainer />
          </div>
        </Container>
      </main>
      {/* ----------------------------GUARANTEE--------------------- */}
      <div
        style={{ backgroundColor: "rgb(123, 83, 27)", width: "100%" }}
        id="guarantee"
      >
        <div class="text-center text-white py-5">
          <h2
            class="text-uppercase"
            style={{ fontSize: "52px", fontWeight: "bold" }}
          >
            our guarantee
          </h2>
          <p style={{ padding: "0% 20%", fontSize: "18px" }}>
            14-DAYS Money Back Guarantee. At AmericaComebackFoundation, we truly
            offer the most stunning, trendy highest-quality products in the
            world. We will do WHATEVER it takes with outstanding customer
            service support to assist everyone as we highly value our customer
            satisfaction with absolute ZERO risk.
          </p>
        </div>
      </div>

      {/* ---------------------------QUESTIONS----------------------- */}
      <div className="questions text-white py-5">
        <Container>
          <p
            className="text-uppercase text-center  "
            style={{ fontSize: "50px", fontWeight: "bolder" }}
          >
            frequnetly asked questions
          </p>
          <FaqComponent />
        </Container>
      </div>

      {/* --------------------------FOOTER-------------------------- */}
      <footer className="bg-dark text-white py-5" style={{ fontSize: "14px" }}>
        <Container className="text-center w-75 py-5">
          <img
            src="https://d33wubrfki0l68.cloudfront.net/2a951110d6ff2e88a45c0893324fd461ac675719/21a35/assets/logo-1.png"
            alt="logo"
            width="150"
            className="mb-2"
          />
          <br />
          <span
            className="text-capitalize"
            style={{ fontSize: "16px", fontWeight: "bold" }}
          >
            America comeback card
          </span>
          <p className="pt-4" style={{ fontWeight: "bold" }}>
            This is a commemorative America Comeback Card and is solely intended
            as memorabilia.
          </p>
          <p>
            This site and the products and services offered on this site are not
            associated, affiliated, endorsed, or sponsored by Donald Trump, nor,
            have they been reviewed tested or certified by Donald Trump.
          </p>
          <p style={{ fontWeight: "bold" }}>
            americacomebackfoundation@gmail.com
          </p>
          <p style={{ fontWeight: "bold" }}>Copyright © 2023 </p>
          <p style={{ fontWeight: "bold" }}>
            Unauthorized duplication or publication of any materials from this
            site is expressly prohibited.
          </p>
        </Container>
      </footer>
    </div>
  );
}

export default App;
