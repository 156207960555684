import card from '../images/card_back_trump-removebg-preview.png'

export const priceData = [
  {
    heading: "1x america comeback card",
    price: "199.99$",
    rate: "★★★★★",
    value: "Best value",
    button: "buy it now",
    text: "1x America Comeback Card - 199.99$",
    description: "+ free shipping and handling",
    link: "https://copecart.com/us/products/7c6323d9/checkout",
    image: card,
  },
  {
    heading: "3x america comeback card",
    price: "369.99$",
    rate: "★★★★★",
    value: "Best value",
    button: "buy it now",
    text: "3x America Comeback Card - 369.99$",
    description: "+ free shipping and handling",
    link: "https://copecart.com/us/products/c5e7c6a2/checkout",
    image: card,
  },
  {
    heading: "5x America Comeback Card",
    price: "599.99$",
    rate: "★★★★★",
    value: "Best value",
    button: "buy it now",
    text: "5x America Comeback Card  - 599.99$",
    description: "+ free shipping and handling",
    link: "https://copecart.com/us/products/2e8a5177/checkout",
    image: card,
  },
  {
    heading: "15x America Comeback Card",
    price: "1599.99$",
    rate: "★★★★★",
    value: "Best value",
    button: "buy it now",
    text: "15x America Comeback Card - 1599.99$",
    description: "+ free shipping and handling",
    link: "https://copecart.com/us/products/fcf2c521/checkout",
    image: card,
  },
  {
    heading: "30x America Comeback Card",
    price: "2499.99$",
    rate: "★★★★★",
    value: "Best value",
    button: "buy it now",
    offer: "family offer",
    text: "30x America Comeback Card - 2499.99$",
    description: "+ free shipping and handling",
    offer: "family offer",
    link: "https://copecart.com/us/products/7d2a502f/checkout",
    image: card,
  },
  {
    heading: "45x America Comeback Card",
    price: "4299.99$",
    rate: "★★★★★",
    value: "Best value",
    button: "buy it now",
    offer: "best offer",
    text: "45x America Comeback Card - 4299.99$",
    description: "+ free shipping and handling",
    offer: "best offer",
    link: "https://copecart.com/us/products/d871e211/checkout",
    image: card,
  },
];
