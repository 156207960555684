import React, { useState } from 'react';
import { Image, Col } from 'react-bootstrap';
import { priceData } from './cardData';

export const CardContainer = () => {
  const lastFourItems = priceData.slice(priceData.length - 4);

  return (
    <div id="cardContainer" className="row justify-content-center pb-5">
      {lastFourItems.map((item, index) => (
        <Col lg={3} md={12} sm={12}
          key={index}
          className={`best-offers  ${index >= 2 && item.offer ? ' larger' : ''}`}
          style={{
            height: index >= 2 ? '530px' : '470px',
          }}
        >
          {item.offer && <h4 className="card-offer" style={{fontSize:"23px", fontWeight:"bolder"}}>{item.offer}</h4>}
          <Image src={item.image} alt={item.heading} />
          <h4 className="text-capitalize py-3" style={{fontSize:"23px"}}>{item.heading}</h4>
          <h3 style={{fontSize:"30px", fontWeight:"bolder"}}>{item.price}</h3>
          <h4 className="rate">{item.rate}</h4>
          <div className="d-flex justify-content-center align-items-center">
            <i className="text-white pb-2 fa-solid fa-gift"></i>
            <h5 className="py-2" style={{fontSize:"20px", fontWeight:"bolder"}}>{item.value}</h5>
          </div>
          <button className="text-uppercase">
            <a target="_blank" style={{fontSize:"20px", fontWeight:"bolder"}} href={item.link}>{item.button}</a>
          </button>
        </Col>
      ))}
    </div>
  );
};

